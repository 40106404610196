import React, { useState } from 'react';

function InteractiveBox() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open("https://twitter.com/dailycryptocat", "_blank");
  };

  return (
    <div 
      className="interactive-box" 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickable
    >
      {isHovered ?  " AI powered bot. Using Python and openAI" : "Follow @DailyCryptoCat on Twitter"}
    </div>
  );
}

export default InteractiveBox;
