import React, { useState } from 'react';

function InteractiveBox() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div 
      className="interactive-box" 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? "Tech Stack: React, CSS, JavaScript, Node" : "This Website is Hosted on Google Cloud"}
    </div>
  );
}

export default InteractiveBox;
